

















import Vue from "vue";
import { skipWaiting } from "../initializeServiceWorker";

export default Vue.extend({
  name: "Sidenav",
  computed: {
    currentUser() {
      return this.$store.direct.state.currentUser;
    },
    open(): boolean {
      return this.$store.direct.state.ui.extrasOpen;
    }
  },
  methods: {
    closeSideNav() {
      void this.$store.direct.dispatch.toggleExtrasMenu();
    },
    reloadApp() {
      window.location.reload();
    },
    activateNewServiceWorker() {
      skipWaiting();
    }
  }
});
