



















import Vue from "vue";
import BackArrowIcon from "../icons/BackArrow.vue";
import Logo from "./Logo.vue";
import MenuIcon from "../icons/Menu.vue";

import router from "../router";

export default Vue.extend({
  name: "Navbar",
  components: {
    BackArrowIcon,
    Logo,
    MenuIcon
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goBack() {
      if (window.history.length) {
        window.history.back();
      } else {
        void router.push("/app");
      }
    }
  }
});
