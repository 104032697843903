













import Vue from "vue";

import LoadingScreen from "@/components/LoadingScreen.vue";
import Navbar from "@/components/Navbar.vue";
import NoAccess from "./NoAccess.vue";
import Sidenav from "@/components/Sidenav.vue";

export default Vue.extend({
  name: "App",
  components: {
    LoadingScreen,
    Navbar,
    NoAccess,
    Sidenav
  },
  computed: {
    dataLoaded(): boolean {
      return this.$store.direct.state.currentUser.dataLoaded;
    },
    hasAccess(): boolean {
      return this.$store.direct.getters.currentUserHasGlobalAccess;
    },
    showsBackButton(): boolean {
      // Show back button if we're not at the root.
      return !this.$route.path.endsWith("app");
    },
    loading(): boolean {
      return !this.dataLoaded;
    }
  },
  mounted() {
    this.$store.direct.dispatch
      .getCurrentUserData()
      .then(() => {
        void this.$store.direct.dispatch.watchCurrentUser();
      })
      .catch(console.error);
  }
});
