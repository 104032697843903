











import LoadingIndicator from "./LoadingIndicator.vue";

export default {
  name: "LoadingScreen",
  components: {
    LoadingIndicator
  }
};
